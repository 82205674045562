<template>
  <div>
    <v-dialog v-model="isEditUser" persistent max-width="800">
      <v-card>
        <v-card-title>
          {{ $t('edit') }}{{ $t('user') }} <v-spacer></v-spacer>
          <v-btn icon color="error" @click="$emit('update:is-edit-user', false)">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="formEditUser" @submit.prevent="updateUserData">
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <div align="center">
                  <v-avatar size="150" color="#9E9E9E">
                    <v-img :src="blob_image"></v-img>
                  </v-avatar>
                  <br />
                  <v-btn color="primary" class="mt-1 ma-1" small @click="$refs.refInputEl.click()">
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('upload_pictures') }}</span>
                  </v-btn>
                  <input
                    ref="refInputEl"
                    type="file"
                    accept=".jpeg,.png,.jpg,GIF"
                    :hidden="true"
                    @change="previewFiles"
                  />
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <v-select
                      v-model="dataEditLocal.role_id"
                      :items="roleList"
                      dense
                      outlined
                      disabled
                      :label="$t('role')"
                      :item-text="$i18n.locale"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-2">
                    <v-text-field v-model="shop_name" :label="$t('clinic')" dense outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-2">
                    <v-select
                      v-model="dataEditLocal.user_type_id"
                      :items="userTypeList"
                      dense
                      outlined
                      disabled
                      :label="$t('type')"
                      :item-text="$i18n.locale"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field v-model="dataEditLocal.username" label="username" disabled dense outlined></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model="dataEditLocal.user_fullname"
                  dense
                  :rules="[required]"
                  outlined
                  :label="$t('firstname_lastname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-2" md="4">
                <v-text-field
                  v-model="dataEditLocal.user_tel"
                  dense
                  disabled
                  outlined
                  :label="$t('tel')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field v-model="dataEditLocal.user_email" dense outlined :label="$t('email')"></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model="dataEditLocal.user_license"
                  dense
                  outlined
                  :label="$t('license_number')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-textarea
                  v-model="dataEditLocal.user_address"
                  dense
                  outlined
                  rows="2"
                  :label="$t('address')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-textarea
                  v-model="dataEditLocal.user_comment"
                  dense
                  outlined
                  rows="2"
                  :label="$t('comment')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="dataEditLocal.teams_commis1"
                  :label="$t('percent_commission_drug')"
                  type="number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="dataEditLocal.teams_commis2"
                  :label="$t('percent_commission_equipment')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="dataEditLocal.teams_commis3"
                  :label="$t('precent_commission_course')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model.trim="dataEditLocal.teams_commis4"
                  :label="$t('precent_commission_member')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model.trim="dataEditLocal.teams_commis5"
                  :label="$t('precent_commission_check')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secodary" outlined @click="$emit('update:is-edit-user', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required, telValidator } from '@core/utils/validation'
import { mdiClose, mdiCloudUpload, mdiEye, mdiEyeOff } from '@mdi/js'
import role_list from '@/@fake-db/data/role_list.json'
import user_type from '@/@fake-db/data/user_type.json'
import user from '@/api/systemSetting/user'
import store from '@/store'

export default {
  model: {
    prop: 'isEditUser',
    event: 'update:is-edit-user',
  },
  props: {
    isEditUser: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditUser = ref(null)
    const showPassword = ref(false)
    const dataEditLocal = ref({})
    const blob_image = ref(null)
    const password = ref('')
    const roleList = ref(role_list.data)
    const userTypeList = ref(user_type.data)
    const shop_name = localStorage.getItem('shop_name')
    const user_image_current = ref(null)
    const loading = ref(false)
    const usernameValidator = ref('')
    const telValidator2 = ref('')
    const { userUpdate } = user
    watch(
      () => props.isEditUser,
      value => {
        if (value) {
          dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
          blob_image.value = dataEditLocal.value.user_image
          dataEditLocal.value.user_type_id = +dataEditLocal.value.user_type_id
          dataEditLocal.value.role_id = +dataEditLocal.value.role_id
          user_image_current.value = dataEditLocal.value.user_image
        }
      },
    )
    const previewFiles = event => {
      dataEditLocal.value.user_image = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    const updateUserData = () => {
      const isFormValid = formEditUser.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('user_id', dataEditLocal.value.user_id)
      formData.append('user_image', dataEditLocal.value.user_image)
      formData.append('user_email', dataEditLocal.value.user_email)
      formData.append('user_fullname', dataEditLocal.value.user_fullname)
      formData.append('user_address', dataEditLocal.value.user_address)
      formData.append('user_tel', dataEditLocal.value.user_tel)
      formData.append('user_license', dataEditLocal.value.user_license)
      formData.append('user_comment', dataEditLocal.value.user_comment)
      formData.append('teams_commis1', dataEditLocal.value.teams_commis1)
      formData.append('teams_commis2', dataEditLocal.value.teams_commis2)
      formData.append('teams_commis3', dataEditLocal.value.teams_commis3)
      formData.append('teams_commis4', dataEditLocal.value.teams_commis4)
      formData.append('teams_commis5', dataEditLocal.value.teams_commis5)
      formData.append('role_id', dataEditLocal.value.role_id)
      formData.append('user_image_current', user_image_current.value)

      userUpdate(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-user', false)
        emit('onUpdate', res.response)
        loading.value = false
      })
    }

    return {
      formEditUser,
      password,
      showPassword,
      userTypeList,
      shop_name,
      roleList,
      loading,
      blob_image,
      dataEditLocal,
      required,
      telValidator,
      previewFiles,
      usernameValidator,
      telValidator2,
      updateUserData,
      icons: {
        mdiClose,
        mdiCloudUpload,
        mdiEye,
        mdiEyeOff,
      },
    }
  },
}
</script>
