import role_list from '@/@fake-db/data/role_listFilter.json'
import profile from '@/api/profile'
import shop from '@/api/shop'
import user from '@/api/systemSetting/user'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

export default function useUserList() {
  const dataTableList = ref([])
  const searchtext = ref('')
  const user_status_id = ref(1)
  const user_role_id = ref('')
  const user_profile_type = ref('')
  const user_profile_id = ref('')
  const tableColumns = [
    {
      text: '#',
      align: 'start',
      value: 'user_id',
      width: 50,
    },
    { text: i18n.t('picture'), value: 'user_image', width: 110 },
    { text: i18n.t('username'), value: 'username', width: 130 },
    { text: i18n.t('name'), value: 'user_fullname' },
    { text: i18n.t('tel'), value: 'user_tel' },
    { text: i18n.t('license_number'), value: 'user_license', width: 180 },
    { text: i18n.t('role'), value: 'role_id', width: 120 },
    { text: i18n.t('type'), value: 'user_type_id', width: 120 },
    { text: i18n.t('status'), value: 'user_status_id', width: 100 },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 250,
      align: 'center',
    },
  ]
  const segmentId = ref(0)
  const totalPage = ref(0)
  const totalDataTableList = ref(0)
  const loading = ref(false)
  const options = ref({})
  const roleList = ref(role_list.data)
  const role_id = ref('')
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { userList } = user
  const { shop: shopGet } = shop

  const packageshop_name = ref('')
  const packageshop_usemanager = ref('')
  const packageshop_useuser = ref('')
  const packageshop_usedoctor = ref('')

  shopGet().then(res => {
    packageshop_usemanager.value = res.packageshop_usemanager
    packageshop_useuser.value = res.packageshop_useuser
    packageshop_usedoctor.value = res.packageshop_usedoctor
  })

  profile().then(res => {
    user_role_id.value = res.role_id
    user_profile_type.value = res.user_type_id
    user_profile_id.value = res.user_id
  })
  const fetchDataTable = event => {
    loading.value = true
    const body = {
      searchtext: searchtext.value,
      user_status_id: user_status_id.value,
      role_id: role_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }
    userList(body).then(res => {
      const { count, count_of_page, data, segment, packageshop_name: packageName } = res
      dataTableList.value = data
      packageshop_name.value = packageName
      totalDataTableList.value = count
      totalPage.value = count_of_page
      segmentId.value = segment
      if (event == 'add') {
        options.value.page = 1
      } else if (event == 'delete') {
        if (+options.value.page >= +count_of_page) {
          options.value.page = count_of_page
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, user_status_id, role_id, options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    packageshop_name,
    packageshop_usemanager,
    packageshop_useuser,
    packageshop_usedoctor,
    fetchDataTable,
    user_role_id,
    user_profile_type,
    user_profile_id,
    searchtext,
    user_status_id,
    tableColumns,
    loading,
    options,
    footer,
    dataTableList,
    totalDataTableList,
    totalPage,
    segmentId,
    roleList,
    role_id,
  }
}
