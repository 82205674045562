<template>
  <div>
    <v-dialog v-model="isAddNewUser" persistent max-width="800">
      <v-card>
        <v-card-title>
          {{ $t('add_user') }} <v-spacer></v-spacer>
          <v-btn icon color="error" @click="$emit('update:is-add-new-user', false)">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="formAddNewUser" @submit.prevent="createNewUser">
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <div align="center">
                  <v-avatar size="150" color="#9E9E9E">
                    <v-img :src="blob_image"></v-img>
                  </v-avatar>
                  <br />
                  <v-btn color="primary" class="mt-1 ma-1" small @click="$refs.refInputEl.click()">
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('upload_pictures') }}</span>
                  </v-btn>
                  <input
                    ref="refInputEl"
                    type="file"
                    accept=".jpeg,.png,.jpg,GIF"
                    :hidden="true"
                    @change="previewFiles"
                  />
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <v-select
                      v-model="role_id"
                      :items="roleList"
                      dense
                      outlined
                      :label="$t('role')"
                      :item-text="$i18n.locale"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-2">
                    <v-text-field v-model="shop_name" :label="$t('clinic')" dense outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-2">
                    <v-select
                      v-model="user_list_id"
                      :items="userTypeList"
                      dense
                      outlined
                      disabled
                      :label="$t('type')"
                      :item-text="$i18n.locale"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model="username"
                  label="username"
                  :rules="[required, usernameValidator]"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model="password"
                  label="password"
                  dense
                  :rules="[required]"
                  outlined
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model="user_fullname"
                  dense
                  :rules="[required]"
                  outlined
                  :label="$t('firstname_lastname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-2" md="6">
                <v-text-field
                  v-model="user_tel"
                  dense
                  :rules="[required, telValidator, telValidator2]"
                  outlined
                  :label="$t('tel')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field v-model="user_email" dense outlined :label="$t('email')"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field v-model="user_license" dense outlined :label="$t('license_number')"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-textarea v-model="user_address" dense outlined rows="2" :label="$t('address')"></v-textarea>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-textarea v-model="user_comment" dense outlined rows="2" :label="$t('comment')"></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="teams_commis1"
                  :label="$t('percent_commission_drug')"
                  type="number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="teams_commis2"
                  :label="$t('percent_commission_equipment')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-2">
                <v-text-field
                  v-model.trim="teams_commis3"
                  :label="$t('precent_commission_course')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model.trim="teams_commis4"
                  :label="$t('precent_commission_member')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <v-text-field
                  v-model.trim="teams_commis5"
                  :label="$t('precent_commission_check')"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secodary" outlined @click="$emit('update:is-add-new-user', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiClose, mdiCloudUpload, mdiEye, mdiEyeOff } from '@mdi/js'
import { ref, onMounted, watch } from '@vue/composition-api'
import { required, telValidator } from '@core/utils/validation'
import role_list from '@/@fake-db/data/role_list.json'
import user_type from '@/@fake-db/data/user_type.json'
import user from '@/api/systemSetting/user'
import shop from '@/api/shop'
import validation from '@/api/validation'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewUser',
    event: 'update:is-add-new-user',
  },
  props: {
    isAddNewUser: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showPassword = ref(false)
    const formAddNewUser = ref(null)
    const blob_image = ref(null)
    const roleList = ref(role_list.data)
    const role_id = ref(1)
    const loading = ref(false)
    const shop_name = localStorage.getItem('shop_name')
    const userTypeList = ref(user_type.data)
    const user_list_id = ref(2)

    const user_image = ref('')
    const username = ref('')
    const password = ref('')
    const user_fullname = ref('')
    const user_email = ref('')
    const user_tel = ref('')
    const user_license = ref('')
    const user_address = ref('')
    const user_comment = ref('')
    const teams_commis1 = ref('0')
    const teams_commis2 = ref('0')
    const teams_commis3 = ref('0')
    const teams_commis4 = ref('0')
    const teams_commis5 = ref('0')

    const countManager = ref(0)
    const countStaff = ref(0)
    const countDoctor = ref(0)

    const usernameValidator = ref('')
    const telValidator2 = ref('')

    const { userList, userAdd } = user
    const { shop: shopGet } = shop
    const { checkUsernameUser, checkTelUser } = validation
    onMounted(() => {
      checkRole()
    })
    const checkRole = () => {
      countManager.value = 0
      countStaff.value = 0
      countDoctor.value = 0
      roleList.value = [
        {
          value: 1,
          th: 'ผู้จัดการ',
          en: 'Manager',
          la: 'ຜູ້​ຈັດ​ການ',
        },
        {
          value: 2,
          th: 'พนักงาน',
          en: 'Employee',
          la: 'ພະນັກງານ',
        },
        {
          value: 3,
          th: 'เเพทย์',
          en: 'Doctor',
          la: 'ທ່ານ ໝໍ',
        },
//        {
//          value: 4,
//          th: 'การตลาด',
//          en: 'Marketing',
//          la: 'ການຕະຫຼາດ',
//        },
      ]
      const body = {
        searchtext: '',
        user_status_id: '1',
        active_page: '',
        per_page: 100,
      }
      userList(body).then(res => {
        const { data } = res
        data.forEach(item => {
          if (item.role_id == '1') {
            countManager.value += 1
          } else if (item.role_id == '2') {
         // } else if (item.role_id == '2' || item.role_id == '4') {
            countStaff.value += 1
          } else if (item.role_id == '3') {
            countDoctor.value += 1
          }
        })
        getShop(countManager, countStaff, countDoctor)
      })
    }
    const getShop = (countManager, countStaff, countDoctor) => {
      shopGet().then(res => {
        if (countManager.value >= parseInt(res.packageshop_usemanager)) {
          const spliceIndex = roleList.value.findIndex(item => item.value == 1)
          roleList.value.splice(spliceIndex, 1)
        }
        if (countStaff.value >= parseInt(res.packageshop_useuser)) {
          const spliceIndex1 = roleList.value.findIndex(item => item.value == 2)
        //  const spliceIndex2 = roleList.value.findIndex(item => item.value == 4)
          roleList.value.splice(spliceIndex1, 1)
         // roleList.value.splice(spliceIndex2, 1)
        }
        if (countDoctor.value >= parseInt(res.packageshop_usedoctor)) {
          const spliceIndex = roleList.value.findIndex(item => item.value == 3)
          roleList.value.splice(spliceIndex, 1)
        }
        role_id.value = roleList.value[0].value
      })
    }
    const createNewUser = () => {
      const isFormValid = formAddNewUser.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('user_image', user_image.value)
      formData.append('username', username.value)
      formData.append('password', password.value)
      formData.append('user_email', user_email.value)
      formData.append('user_fullname', user_fullname.value)
      formData.append('user_address', user_address.value)
      formData.append('user_tel', user_tel.value)
      formData.append('user_license', user_license.value)
      formData.append('role_id', role_id.value)
      formData.append('user_comment', user_comment.value)
      formData.append('teams_commis1', teams_commis1.value)
      formData.append('teams_commis2', teams_commis2.value)
      formData.append('teams_commis3', teams_commis3.value)
      formData.append('teams_commis4', teams_commis4.value)
      formData.append('teams_commis5', teams_commis4.value)
      userAdd(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'completed_successfully' : 'unsuccessful',
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-user', false)
        emit('onAdd', res.response)
        loading.value = false
      })
    }
    watch(
      () => props.isAddNewUser,
      () => {
        if (props.isAddNewUser) {
          checkRole()
          clearData()
        }
      },
    )
    const clearData = () => {
      user_image.value = ''
      username.value = ''
      password.value = ''
      user_fullname.value = ''
      user_email.value = ''
      user_tel.value = ''
      user_license.value = ''
      user_address.value = ''
      user_comment.value = ''
      teams_commis1.value = '0'
      teams_commis2.value = '0'
      teams_commis3.value = '0'
      teams_commis4.value = '0'
      role_id.value = roleList.value[0].value
    }
    const previewFiles = event => {
      user_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    watch(
      () => username.value,
      () => {
        if (username.value) {
          checkUsernameUser(username.value).then(res => {
            usernameValidator.value = res || i18n.t('this_username_is_already_in_use')
          })
        }
      },
    )
    watch(
      () => user_tel.value,
      value => {
        if (user_tel.value && user_tel.value.length > 9) {
          checkTelUser(user_tel.value).then(res => {
            telValidator2.value = res || i18n.t('this_tel_is_already_in_use')
          })
        }
      },
    )

    return {
      countManager,
      countStaff,
      countDoctor,
      previewFiles,
      user_image,
      teams_commis1,
      teams_commis2,
      teams_commis3,
      teams_commis4,
      teams_commis5,
      user_comment,
      user_license,
      user_address,
      user_fullname,
      user_email,
      user_tel,
      showPassword,
      username,
      password,
      user_list_id,
      userTypeList,
      shop_name,
      loading,
      formAddNewUser,
      required,
      createNewUser,
      blob_image,
      role_id,
      roleList,
      telValidator,
      usernameValidator,
      telValidator2,
      icons: {
        mdiClose,
        mdiCloudUpload,
        mdiEye,
        mdiEyeOff,
      },
    }
  },
}
</script>
