<template>
  <div>
    <AddNewUserList
      v-model="isAddNewUser"
      @onAdd="fetchDataTable('add')"
    />
    <EditUser
      v-model="isEditUser"
      :data-edit="userData"
      @onUpdate="fetchDataTable('update')"
    />
    <ChangeUserPassword
      v-model="isChangePassword"
      :username="username"
      :user-id="String(userId)"
    />

    <LimitUserMenu
      v-model="isChangeLimitMenu"
      :user-id="String(userId)"
      :role-id="String(roleId)"
    />

    <LimitUserData
      v-model="isChangeLimitData"
      :user-data="userData"
      @onUpdate="fetchDataTable()"
    />

    <v-card>
      <v-card-title class="px-2">
        {{ $t('manage_user') }}
        <span class="text-sm ml-2 d-none d-md-block">*{{ $t("shop_package_user_rights") }} : </span>
        <span
          style="color: red"
          class="text-sm d-none d-md-block"
        >
          {{ packageshop_name }} {{ $t("number_of_managers") }}
          {{ packageshop_usemanager }} {{ $t("number_of_staff") }}
          {{ packageshop_useuser }} {{ $t("number_of_doctors") }}
          {{ packageshop_usedoctor }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewUser= true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_user') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewUser= true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="user_status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="role_id"
            :placeholder="$t('role')"
            :label="$t('role')"
            :items="roleList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.user_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template #[`item.user_image`]="{ item }">
          <v-avatar
            :color="item.user_image ? '' : 'primary'"
            :class="item.user_image ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img :src="item.user_image"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.role_id`]="{ item }">
          <span v-if="$i18n.locale == 'th'">{{ item.role_th }}</span>
          <span v-if="$i18n.locale == 'en'">{{ item.role_en }}</span>
        </template>
        <template v-slot:[`item.user_type_id`]="{ item }">
          <v-chip
            v-if="item.user_type_id == 1"
            class="v-chip-light-bg primary--text"
            color="primary"
            small
          >
            <i class="fa fa-lock me-1"></i>
            {{ $t('main_user') }}
          </v-chip>
          <v-chip
            v-else
            class="v-chip-light-bg info--text"
            color="info"
            small
          >
            <i class="fa fa-unlock me-1"></i>
            {{ $t('sub_user') }}
          </v-chip>
        </template>
        <template v-slot:[`item.user_status_id`]="{ item }">
          <StatusBlock :status="String(item.user_status_id)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- Edit user -->
          <v-tooltip
            v-if="
              (user_role_id < item.role_id && user_profile_type <= item.user_type_id) ||
                user_profile_type == 1 ||
                user_profile_id == item.user_id
            "
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.user_status_id == 2"
                @click="getDataEdit(item.user_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                disabled
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <!-- Change password -->
          <v-tooltip
            v-if="
              (user_role_id < item.role_id && user_profile_type <= item.user_type_id) ||
                user_profile_type == 1 ||
                user_profile_id == item.user_id
            "
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.user_status_id == 2"
                @click="isChangePassword = true ,username = item.username,userId = item.user_id"
                v-on="on"
              >
                <v-icon>{{ icons.mdiLockReset }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('change_password') }}</span>
          </v-tooltip>
          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                disabled
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiLockReset }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('change_password') }}</span>
          </v-tooltip>

          <!-- Limit menu -->
          <v-tooltip
            v-if="user_role_id == 1 && user_profile_type == 1"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.user_status_id == 1 && item.user_type_id == 1 || item.user_status_id == 2 && item.user_type_id == 1"
                @click="roleId = item.role_id,userId = item.user_id,isChangeLimitMenu = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiFormatListBulleted }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('limit_menu') }}</span>
          </v-tooltip>

          <!-- Limit dashboard -->
          <v-tooltip
            v-if="user_role_id == 1 && user_profile_type == 1"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.user_status_id == 2"
                @click="sendLimitData(item)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiChartDonut }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('user_limit_data') }}</span>
          </v-tooltip>

          <!-- Suspned -->
          <v-tooltip
            v-if="(user_role_id < item.role_id && user_profile_type <= item.user_type_id) || user_profile_type == 1 || user_profile_id == item.user_id"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.user_status_id == 1 && item.user_type_id == 1 || item.user_status_id == 2 && item.user_type_id == 1"
                v-bind="attrs"
                @click="isDeleteUser = true,userId = item.user_id"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('suspend') }}</span>
          </v-tooltip>
          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                disabled
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('suspend') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isDeleteUser"
      persistent
      max-width="530"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>
          {{ $t("confirm_suspension") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title>
          <v-spacer></v-spacer>
          {{ $t('suspended_and_can_not') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="suspendLoading"
            :disabled="suspendLoading"
            @click="suspendUser"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteUser = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  mdiPlus, mdiPencilOutline, mdiLockReset, mdiChartDonut, mdiDeleteOutline, mdiFormatListBulleted, mdiAlertOutline,
} from '@mdi/js'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import useUserList from './useUserList'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewUserList from './AddNewUserList.vue'
import EditUser from './EditUser.vue'
import user from '@/api/systemSetting/user'
import ChangeUserPassword from './ChangeUserPassword.vue'
import LimitUserMenu from './LimitUserMenu.vue'
import LimitUserData from './LimitUserData.vue'
import store from '@/store'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    StatusBlock,
    AddNewUserList,
    EditUser,
    ChangeUserPassword,
    LimitUserMenu,
    LimitUserData,
    Pagination,

  },
  setup() {
    const statusList = ref(basicStatus.data)
    const isAddNewUser = ref(false)
    const isEditUser = ref(false)
    const username = ref('')
    const isChangePassword = ref(false)
    const userData = ref({})
    const userId = ref('')
    const roleId = ref('')
    const isChangeLimitMenu = ref(false)
    const isChangeLimitData = ref(false)
    const isDeleteUser = ref(false)
    const suspendLoading = ref(false)

    const {
      packageshop_name,
      packageshop_usemanager,
      packageshop_useuser,
      packageshop_usedoctor,
      fetchDataTable,
      searchtext,
      user_status_id,
      tableColumns,
      loading,
      options,
      footer,
      totalPage,
      dataTableList,
      totalDataTableList,
      user_role_id,
      user_profile_type,
      user_profile_id,
      segmentId,
      role_id,
      roleList,
    } = useUserList()
    const { userGet, suspendUser: deleteUser } = user

    const suspendUser = () => {
      suspendLoading.value = true
      const body = {
        user_id: userId.value,
        user_status_id: 2,
      }
      deleteUser(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        suspendLoading.value = false
        isDeleteUser.value = false
        fetchDataTable('delete')
      })
    }

    const getDataEdit = id => {
      userGet(id).then(res => {
        userData.value = res
        isEditUser.value = true
      })
    }

    const sendLimitData = item => {
      userData.value = item
      isChangeLimitData.value = true
    }

    return {
      suspendLoading,
      suspendUser,
      isDeleteUser,
      isChangeLimitData,
      userId,
      roleId,
      isChangePassword,
      username,
      isEditUser,
      userData,
      packageshop_name,
      totalPage,
      packageshop_usemanager,
      packageshop_useuser,
      packageshop_usedoctor,
      isChangeLimitMenu,
      fetchDataTable,
      sendLimitData,
      isAddNewUser,
      statusList,
      user_role_id,
      searchtext,
      user_status_id,
      user_profile_type,
      user_profile_id,
      tableColumns,
      loading,
      options,
      footer,
      dataTableList,
      totalDataTableList,
      segmentId,
      roleList,
      role_id,
      getDataEdit,
      icons: {
        mdiPencilOutline,
        mdiLockReset,
        mdiChartDonut,
        mdiDeleteOutline,
        mdiFormatListBulleted,
        mdiPlus,
        mdiAlertOutline,
      },
    }
  },
}
</script>
