<template>
  <div>
    <v-dialog
      v-model="isChangeLimitMenu"
      persistent
      max-width="700"
    >
      <v-card :loading="loading">
        <v-card-title>
          {{ $t("limit_menu") }} <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="$emit('update:is-change-limit-menu', false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  #
                </th>
                <th class="text-left">
                  {{ $t("group_menu") }}
                </th>
                <th class="text-left">
                  {{ $t("menu") }}
                </th>
                <th class="text-left">
                  {{ $t("status") }}
                </th>
                <th class="text-left">
                  {{ $t("limit_menu") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cus, index) in menuList"
                :key="index"
              >
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ cus.group_menu_th }}
                </td>
                <td>
                  {{ cus.menu_th }}
                </td>
                <td>
                  <v-chip
                    v-if="cus.check_stetus_user == 0"
                    class="v-chip-light-bg warning--text"
                    small
                    color="warning"
                  >
                    {{ $t("unlimit") }}
                  </v-chip>
                  <v-chip
                    v-else
                    class="v-chip-light-bg error--text"
                    small
                    color="error"
                  >
                    {{ $t("limit_use") }}
                  </v-chip>
                </td>
                <td>
                  <v-switch
                    v-model="cus.check_stetus_user"
                    :true-value="1"
                    :false-value="0"
                    :loading="processLoading"
                    :disabled="processLoading"
                    @change="updateStatus(cus.menu_id, cus.check_stetus_user)"
                  ></v-switch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="processDefaults"
            :disabled="processDefaults"
            @click="getDefalut()"
          >
            {{ $t("default") }}
          </v-btn>
          <v-btn
            color="red"
            outlined
            @click="$emit('update:is-change-limit-menu', false)"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import user from '@/api/systemSetting/user'
import store from '@/store'

export default {
  model: {
    prop: 'isChangeLimitMenu',
    event: 'update:is-change-limit-menu',
  },
  props: {
    isChangeLimitMenu: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    roleId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const menuList = ref([])
    const loading = ref(false)
    const processLoading = ref(false)
    const processDefaults = ref(false)

    const {
      menuLimitList, closeMenu, openMenu, defaultMenu,
    } = user

    const getMenuList = () => {
      menuLimitList(props.userId, props.roleId).then(res => {
        menuList.value = res.data
        loading.value = false
        processLoading.value = false
        processDefaults.value = false
      })
    }

    watch(() => props.isChangeLimitMenu, () => {
      if (props.isChangeLimitMenu) {
        loading.value = true
        getMenuList()
      }
    })

    const updateStatus = (menuId, status) => {
      processLoading.value = true
      const body = {
        user_id: props.userId,
        menu_id: menuId,
      }
      if (status == '0') {
        closeMenu(body).then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })
          getMenuList()
        })
      } else {
        openMenu(body).then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })
          getMenuList()
        })
      }
    }

    const getDefalut = () => {
      const body = {
        user_id: props.userId,
      }
      defaultMenu(body).then(res => {
        processDefaults.value = true
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getMenuList()
      })
    }

    return {
      menuList,
      processLoading,
      processDefaults,

      updateStatus,
      defaultMenu,
      getDefalut,
      loading,
      icons: {
        mdiClose,
      },
    }
  },

}
</script>
