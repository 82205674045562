<template>
  <div>
    <v-dialog
      v-model="isChangePassword"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>{{ $t('change_password') }}</v-card-title>
        <v-form
          ref="formChangePassword"
          @submit.prevent="updatePassword"
        >
          <v-card-text>
            <v-text-field
              v-model="username"
              :label="$t('username')"
              disabled
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="password"
              :label="$t('password')"
              :rules="[required]"
              type="password"
              dense
              class="mt-2"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :rules="[required]"
              class="mt-2"
              dense
              :label="$t('confirm_password')"
              type="password"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:change-password', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import user from '@/api/systemSetting/user'
import store from '@/store'

export default {
  model: {
    prop: 'isChangePassword',
    event: 'update:change-password',
  },
  props: {
    isChangePassword: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const formChangePassword = ref(null)
    const password = ref('')
    const confirmPassword = ref('')

    const loading = ref(false)

    const { updatePassword: updatePass } = user

    const updatePassword = () => {
      const isFormValid = formChangePassword.value.validate()
      if (!isFormValid) return
      loading.value = true
      if (password.value !== confirmPassword.value) {
        store.commit('app/ALERT', {
          message: 'password_not_math',
          color: 'error',
        })
        password.value = ''
        confirmPassword.value = ''
        loading.value = false
      } else {
        updatePass({
          user_id: props.userId,
          username: props.username,
          password_new: password.value,
          password_confirm: confirmPassword.value,
        }).then(res => {
          store.commit('app/ALERT', {
            message: res.response ? 'completed_successfully' : 'unsuccessful',
            color: res.response ? 'success' : 'error',
          })
          password.value = ''
          confirmPassword.value = ''
          loading.value = false
          emit('update:change-password', false)
        })
      }
    }

    return {
      loading,

      updatePassword,
      required,
      formChangePassword,
      password,
      confirmPassword,

    }
  },

}
</script>
