import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

// Node api
const userList = async body => {
  //await refreshToken()
  const response = await api2
    .post({
      path: 'user/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get user list err : ', err)

      return {}
    })

  return response
}

// Node api
const userGet = async id => {
  ////await refreshToken()
  const response = await api2
    .get({
      path: 'user',
      param: id,
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.error('get user by id err : ', err)

      return {}
    })

  return response
}

const userAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'user',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add user  err : ', err)

      return {}
    })

  return response
}

// Node api
const userUpdate = async body => {
  const response = await api
    .post({
      path: 'user/edit',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update user  err : ', err)

      return {}
    })

  return response
}

const updatePassword = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/password',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update user password  err : ', err)

      return {}
    })

  return response
}

const menuLimitList = async (userId, roleId) => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'user/limit',
      param: `${userId}/${roleId}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get menu limit list   err : ', err)

      return {}
    })

  return response
}
const closeMenu = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/limitclose',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('close menu   err : ', err)

      return {}
    })

  return response
}

const openMenu = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/limitopen',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('open menu err : ', err)

      return {}
    })

  return response
}

const defaultMenu = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/limitcloseall',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get default menu err : ', err)

      return {}
    })

  return response
}
const limitData = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/limitdashboard',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update limit user data err : ', err)

      return {}
    })

  return response
}
const suspendUser = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'user/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('suspend user data err : ', err)

      return {}
    })

  return response
}

export default {
  userList,
  userGet,
  userAdd,
  userUpdate,
  updatePassword,
  menuLimitList,
  closeMenu,
  openMenu,
  defaultMenu,
  limitData,
  suspendUser,
}
