<template>
  <div>
    <v-dialog
      v-model="isChangeLimitData"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('user_limit_data') }}
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model.trim="user_dashboard_1"
            :label="$t('dashboard_1')"
            :false-value="0"
            :true-value="1"
          ></v-checkbox>
          <v-checkbox
            v-model.trim="user_dashboard_2"
            :label="$t('dashboard_2')"
            :false-value="0"
            :true-value="1"
          ></v-checkbox>
          <v-checkbox
            v-model.trim="user_dashboard_3"
            :label="$t('dashboard_3')"
            :false-value="0"
            :true-value="1"
          ></v-checkbox>
          <v-checkbox
            v-model.trim="user_dashboard_4"
            :label="$t('dashboard_4')"
            :false-value="0"
            :true-value="1"
          ></v-checkbox>
          <v-checkbox
            v-model.trim="user_data_customers"
            :label="$t('not_show_cost_drug_equipment')"
            :false-value="0"
            :true-value="1"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="updateLimitData"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="scondary"
            outlined
            @click="$emit('update:is-change-limit-data', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import user from '@/api/systemSetting/user'
import store from '@/store'

export default {
  model: {
    prop: 'isChangeLimitData',
    event: 'update:is-change-limit-data',
  },
  props: {
    isChangeLimitData: {
      type: Boolean,
      default: false,
    },

    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const user_dashboard_1 = ref(0)
    const user_dashboard_2 = ref(0)
    const user_dashboard_3 = ref(0)
    const user_dashboard_4 = ref(0)
    const user_data_customers = ref(0)

    const message = ref('')
    const colorStatus = ref('')
    const statusShowAlert = ref(false)
    const loading = ref(false)

    const { limitData } = user
    watch(() => props.userData, newVal => {
      if (newVal) {
        user_dashboard_1.value = newVal.user_dashboard_1
        user_dashboard_2.value = newVal.user_dashboard_2
        user_dashboard_3.value = newVal.user_dashboard_3
        user_dashboard_4.value = newVal.user_dashboard_4
        user_data_customers.value = newVal.user_data_customers
      }
    })

    const updateLimitData = () => {
      loading.value = true
      const body = {
        user_id: props.userData.user_id,
        user_dashboard_1: user_dashboard_1.value,
        user_dashboard_2: user_dashboard_2.value,
        user_dashboard_3: user_dashboard_3.value,
        user_dashboard_4: user_dashboard_4.value,
        user_data_customers: user_data_customers.value,
      }
      limitData(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-change-limit-data', false)
        emit('onUpdate', res.response)
      })
    }

    return {
      loading,
      user_dashboard_1,
      user_dashboard_2,
      user_dashboard_3,
      user_dashboard_4,
      user_data_customers,
      message,
      colorStatus,
      statusShowAlert,
      updateLimitData,
    }
  },

}
</script>
