import refreshToken from '@/plugins/refresh-token'
import { api } from './main'
import { api as api2 } from './mainNode'

// Add customer check
const checkCustomerIdcardAndFirstname = async body => {
  //await refreshToken()
  const response = await api2
    .post({
      path: 'customer/check/card',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.log('check customer err : ', err)

      return {}
    })

  return response
}
const checkCustomerAdd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'customer/check',
      body,
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.log('check customer err : ', err)

      return {}
    })

  return response
}

// Add user check
const checkUsernameUser = async username => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'user/checkusername',
      param: username,
    })
    .then(res => res.data)
    .catch(err => {
      console.log('check username user err : ', err)

      return {}
    })

  return response
}

const checkTelUser = async tel => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'user/checkusertel',
      param: tel,
    })
    .then(res => res.data)
    .catch(err => {
      console.log('check telephone number user err : ', err)

      return {}
    })

  return response
}

const checkTelRegister = async tel => {
  const response = await api
    .get({
      path: 'register/checktel',
      param: tel,
    })
    .then(res => res.data)
    .catch(err => {
      console.log('check telephone as register err : ', err)

      return {}
    })

  return response
}

const checkEmail = async email => {
  const response = await api2
    .post({
      path: 'register/checkemail',
      body: { email },
    })
    .then(res => res)
    .catch(err => {
      console.log('check email as register err : ', err)

      return {}
    })

  return response
}

export default {
  checkCustomerAdd,
  checkUsernameUser,
  checkTelUser,
  checkCustomerIdcardAndFirstname,
  checkTelRegister,
  checkEmail,
}
